import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { LoadingInterceptor } from './loading.interceptor';
import { ErrorHandlingInterceptor } from './error-handling.interceptor';

/**
 * Interceptors Wraper
 * An array to wrap all interceptors using at the app
 */
export const INTERCERPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlingInterceptor,
    multi: true,
  },
];
