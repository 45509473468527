import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { StringLimitPipe } from './string-limit.pipe';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { RelativeDatePipe } from './relative-time.pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [ErrorComponent, StringLimitPipe, RelativeDatePipe],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PerfectScrollbarModule,
    NgSelectModule,
    NgxDatatableModule,
    SimpleNotificationsModule.forRoot(),
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    TranslateModule,
    CommonModule,
    NgSelectModule,
    StringLimitPipe,
    RelativeDatePipe,
    SimpleNotificationsModule,
    NgxDatatableModule,
  ],
})
export class SharedModule {}
