import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { take } from 'rxjs/operators';
import { RootService } from 'src/app/shared/root.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthUsersService } from '../services/auth-users.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Output() closeModal = new EventEmitter<any>();

  emailModel = '';
  passwordModel = '';
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'report-modal modal-lg',
    keyboard: true,
  };
  buttonDisabled = false;
  buttonState = '';
  changePassword: FormGroup;
  constructor(
    private service: AuthUsersService,
    private modalService: BsModalService,
    private notifications: NotificationsService,
    private router: Router,
    private storage: StorageService,
    private formBuilder: FormBuilder,
    private root: RootService
  ) {}

  ngOnInit() {
    console.log('data');
    this.root.resetData();
    this.getForm();
  }

  getForm() {
    this.changePassword = this.formBuilder.group(
      {
        old_password: ['', Validators.required],
        new_password: ['', Validators.required],
        confirmNewPassword: ['', Validators.required],
      },
      {
        validator: this.matchingPasswords('new_password', 'confirmNewPassword'),
      }
    );
  }

  matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const password = group.controls[passwordKey];
      const passwordConfirmation = group.controls[passwordConfirmationKey];
      if (password.value !== passwordConfirmation.value) {
        return passwordConfirmation.setErrors({ mismatchedPasswords: true });
      }
    };
  }
  onSubmit() {
    if (!this.changePassword.valid || this.buttonDisabled) {
      this.changePassword.markAllAsTouched();
      return;
    }
    this.buttonDisabled = true;
    this.buttonState = 'show-spinner';
    this.service
      .changePassword(this.changePassword.value)
      .pipe(take(1))
      .subscribe(
        ({ status, message, data }) => {
          this.buttonDisabled = false;
          this.buttonState = '';
          if (status !== 200) {
            return this.notifications.create(
              'Error',
              message,
              NotificationType.Bare,
              {
                theClass: 'outline primary',
                timeOut: 6000,
                showProgressBar: false,
              }
            );
          } else {
            this.closeModal.emit();
          }
        },
        (err) => {
          this.buttonDisabled = false;
          this.buttonState = '';
          this.notifications.create(
            'Error',
            'Something went error',
            NotificationType.Bare,
            {
              theClass: 'outline primary',
              timeOut: 6000,
              showProgressBar: false,
            }
          );
        }
      );
  }
}
