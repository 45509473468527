import { Injectable } from '@angular/core';
import { HttpService } from './http-service.service';
import { IMenuItem } from 'src/app/constants/menu';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

const menuItems: IMenuItem[] = [
  {
    icon: 'iconsminds-monitor-analytics',
    label: 'menu.dashboard',
    to: '/app/dashboard',
  },
  {
    icon: 'iconsminds-notepad',
    label: 'menu.customers',
    to: '/app/customers',
  },
  {
    icon: 'iconsminds-notepad',
    label: 'menu.categories',
    to: '/app/categories',
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.trustPoints',
    to: '/app/trustPoints',
    subs: [],
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.autoComplete',
    to: '/app/auto-complete',
    subs: [],
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.offers',
    to: '/app/offers',
    subs: [],
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.requests',
    to: '/app/requests',
    subs: [],
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.notification',
    to: '/app/notification',
    subs: [],
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.city',
    to: '/app/city',
    subs: [],
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.units',
    to: '/app/units',
    subs: [],
  },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.complains',
    to: '/app/complains',
    subs: [],
  },
  // {
  //   icon: 'iconsminds-security-camera',
  //   label: 'menu.reports',
  //   to: '/app/reports',
  //   subs: [],
  // },
  {
    icon: 'iconsminds-security-camera',
    label: 'menu.sub-admin',
    to: '/app/sub-admin',
    subs: [],
  },
];

const URLS = {
  info: '/user/projects',
  notification: '/user/all_notifications',
};

@Injectable({
  providedIn: 'root',
})
export class RootService {
  readonly dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly refresher: BehaviorSubject<number> = new BehaviorSubject(0);
  public mainInfo: BehaviorSubject<any> = new BehaviorSubject({});
  public menuItems: IMenuItem[] = [];
  public filters = {
    project_ids: [''],
    zone_ids: [''],
    date_from: '',
    date_to: '',
  };
  public currentProject: any;
  public currentZone: any;
  public currentDate: any;

  public projectMapper = {};
  public zoneMapper = {};

  constructor(private http: HttpService) { }

  getInfo() {
    setTimeout(() => {
      this.menuItems = menuItems;
      document.body.click();
    }, 1000);
  }

  getNotifications(params: any) {
    return this.http.getReq(URLS.notification, { params }).pipe(take(1));
  }

  public refresh() {
    this.refresher.next(this.refresher.value + 1);
  }

  public resetData() { }
}
