import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Subscription, interval, Subject } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { take, switchMap, startWith, takeUntil } from 'rxjs/operators';
import { RootService } from 'src/app/shared/root.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AuthUsersService } from 'src/app/views/user/services/auth-users.service';
import { ChangePasswordComponent } from 'src/app/views/user/change-password/change-password.component';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit, OnDestroy {
  sidebar: ISidebar;
  subscription: Subscription;
  displayName: string;
  dateRange: Date[] = [];
  // dateRange: Date[] = [new Date(), new Date()];
  modalRef: BsModalRef;

  toggleProject: boolean;

  projects: any[] = [];
  zones: any[] = [];
  notificationsData: any;
  notificationConfg = {
    page: 1,
    read: 0,
  };

  pages = 1;
  total_unread = 0;
  pull_first_time: boolean = true;
  unsubscribeSignal: Subject<void> = new Subject();

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private storage: StorageService,
    public root: RootService,
    private service: AuthUsersService,
    private notifications: NotificationsService,
    private modalService: BsModalService
  ) {}
  @ViewChild('createReport', { static: true })
  ChangePasswordComponent: ChangePasswordComponent;
  ngOnInit() {
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => (this.sidebar = res),
      (err) => console.error(`An error occurred: ${err.message}`)
    );

    this.getUserName();
    this.root.mainInfo.subscribe(({ projects }) => {
      if (!projects) return;

      this.projects = projects;
      this.initData();
    });

    if (localStorage.getItem('token'))
      this.getNotification(this.notificationConfg);
  }

  getNotification(dataConfig) {
    // dataConfig.read = 0;
    // interval(15000)
    //   .pipe(
    //     startWith(0),
    //     switchMap(() => this.root.getNotifications(dataConfig)),
    //     takeUntil(this.unsubscribeSignal.asObservable())
    //   )
    //   .subscribe(({ status, data, message }) => {
    //     if (status !== 200)
    //       return this.notifications.create(
    //         'Error',
    //         message,
    //         NotificationType.Bare,
    //         {
    //           theClass: 'outline danger',
    //           timeOut: 6000,
    //           showProgressBar: false,
    //         }
    //       );
    //     this.total_unread = data.total_unread;
    //     if (this.pull_first_time) {
    //       this.notificationsData = data;
    //       this.pull_first_time = false;
    //     }
    //   });
  }

  markRead() {
    this.notificationConfg.read = 1;
    this.root
      .getNotifications(this.notificationConfg)
      .pipe(takeUntil(this.unsubscribeSignal.asObservable()))
      .subscribe(({ status, data, message }) => {
        if (status !== 200)
          return this.notifications.create(
            'Error',
            message,
            NotificationType.Bare,
            {
              theClass: 'outline danger',
              timeOut: 6000,
              showProgressBar: false,
            }
          );

        this.notificationConfg.read = 0;
        this.notificationsData = data;
        this.total_unread = data.total_unread;
      });
    this.getNotification(this.notificationConfg);
  }

  onScroll() {
    this.pages += 1;
    if (this.pages <= this.notificationsData.total_pages) {
      this.root
        .getNotifications({
          page: this.pages,
          read: 1,
        })
        .pipe(takeUntil(this.unsubscribeSignal.asObservable()))
        .subscribe(({ status, data, message }) => {
          if (status !== 200) {
            return this.notifications.create(
              'Error',
              message,
              NotificationType.Bare,
              {
                theClass: 'outline danger',
                timeOut: 6000,
                showProgressBar: false,
              }
            );
          } else {
            this.notificationConfg.read = 0;
            this.total_unread = data.total_unread;
            this.notificationsData.notifications = this.notificationsData.notifications.concat(
              data.notifications
            );
          }
        });
    }
  }

  /**
   * Init the component data
   *
   * @memberof TopnavComponent
   */
  initData(): void {
    this.projectChanged('all');
    this.zoneChanged('all');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribeSignal.next();
    this.unsubscribeSignal.unsubscribe();
  }

  /**
   * Listen to the project selection change
   *
   * @param {*} item - selected project
   * @memberof TopnavComponent
   */
  projectChanged(item: any): void {
    if (item === 'all') {
      this.zones = [];
      this.root.currentProject = { name: 'All Projects' };
      this.root.currentZone = { name: 'All Zones' };
      delete this.root.filters.project_ids;
      delete this.root.filters.zone_ids;
      this.root.refresh();
      return;
    }
    this.zones = item.zones;
    this.root.currentProject = item;
    this.root.currentZone = { name: 'All Zones' };
    delete this.root.filters.zone_ids;
    this.root.filters.project_ids = [item.id];
    this.root.refresh();
  }

  /**
   * Listen to the zone selection change
   *
   * @param {*} item - selected zone
   * @memberof TopnavComponent
   */
  zoneChanged(item: any, clicked?: boolean): void {
    if (item === 'all') {
      this.root.currentZone = { name: 'All Zones' };
      delete this.root.filters.zone_ids;
      if (clicked) this.root.refresh();
      return;
    }
    this.root.currentZone = item;
    this.root.filters.zone_ids = [item.id];
    this.root.refresh();
  }

  onSelectDate(date: any) {
    if (!date) return;
    this.root.currentDate = date;
    this.root.filters.date_from = date[0].toISOString();
    this.root.filters.date_to = date[1].toISOString();
    this.root.refresh();
  }

  menuButtonClick(
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) {
    if (e) e.stopPropagation();

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  showChangePassword(data: TemplateRef<any>) {
    this.modalRef = this.modalService.show(data, {
      class: 'modal-lg gallery-report-modal change-password',
      backdrop: true,
    });
  }

  mobileMenuButtonClick(
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) {
    if (event) event.stopPropagation();

    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut() {
    this.storage
      .clearStorage()
      .pipe(
        switchMap(() => this.service.logout()),
        take(1)
      )
      .subscribe(() => this.router.navigate(['/user/login']));
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event) {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) input.classList.remove('mobile-view');
  }

  getUserName() {
    this.storage
      .getItem('name')
      .pipe(take(1))
      .subscribe((name) => (this.displayName = name));
  }
}
