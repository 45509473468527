<div class="card auth-card">
  <div class="position-relative image-side">
    <p class="text-white h2">
      Waste Marche : An online platform for Industrial waste trading and
      Management
    </p>
    <p class="white mb-0">Please use old password and new password change it</p>
  </div>
  <div class="form-side">
    <a class="c-pointer">
      <span class="logo-single"></span>
    </a>
    <h6 class="mb-4">Change Password</h6>

    <form [formGroup]="changePassword" novalidate (ngSubmit)="onSubmit()">
      <div class="form-group">
        <span>Password</span>
        <input
          class="form-control"
          type="password"
          placeholder="Enter Your  password"
          formControlName="old_password"
          name="password"
          minlength="8"
        />
        <div
          *ngIf="
            changePassword.controls.old_password.invalid &&
            (changePassword.controls.old_password.dirty ||
              changePassword.controls.old_password.touched)
          "
        >
          <div
            *ngIf="changePassword.controls.old_password.errors?.required"
            class="invalid-tooltip"
          >
            Password is required!
          </div>
          <div
            *ngIf="changePassword.controls.old_password.hasError('minlength')"
            class="invalid-tooltip"
          >
            Password must be at least 8 chars
          </div>
        </div>
      </div>
      <div class="form-group">
        <span>New password</span>
        <input
          class="form-control"
          type="password"
          placeholder="Enter Your new  password"
          formControlName="new_password"
          name="password"
          minlength="8"
        />
        <div
          *ngIf="
            changePassword.controls.new_password.invalid &&
            (changePassword.controls.new_password.dirty ||
              changePassword.controls.new_password.touched)
          "
        >
          <div
            *ngIf="changePassword.controls.new_password.errors?.required"
            class="invalid-tooltip"
          >
            new password is required!
          </div>
          <div
            *ngIf="changePassword.controls.new_password.hasError('minlength')"
            class="invalid-tooltip"
          >
            New password must be at least 8 chars
          </div>
        </div>
      </div>
      <div class="form-group">
        <span>Confirm password</span>
        <input
          class="form-control"
          type="password"
          placeholder="Enter Your new  password"
          formControlName="confirmNewPassword"
          name="password"
          minlength="8"
        />
        <div
          *ngIf="
            changePassword.controls.confirmNewPassword.invalid &&
            (changePassword.controls.confirmNewPassword.dirty ||
              changePassword.controls.confirmNewPassword.touched)
          "
        >
          <div
            *ngIf="changePassword.controls.confirmNewPassword.errors?.required"
            class="invalid-tooltip"
          >
            new password is required!
          </div>
          <div
            *ngIf="
              changePassword.controls.confirmNewPassword.hasError(
                'mismatchedPasswords'
              )
            "
            class="invalid-tooltip"
          >
            Passwords doesn't match
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center"
        style="
          text-align: right !important;
          width: 100%;
          display: block !important;
        "
      >
        <app-state-button
          [btnClass]="'btn btn-primary btn-lg btn-shadow'"
          [currentState]="buttonState"
          [isDisabled]="buttonDisabled"
          click="onLoginClick()"
        >
          Change password
        </app-state-button>
      </div>
    </form>
  </div>
</div>
<simple-notifications></simple-notifications>
