<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a
      (click)="
        menuButtonClick(
          $event,
          sidebar.menuClickCount,
          sidebar.containerClassnames
        );
        (false)
      "
      href="javascript:;"
      class="menu-button d-none d-md-block"
    >
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg>
      {{ ' ' }}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a
      (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)"
      href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>
  </div>

  <a class="navbar-logo" href="/">
    <span class="logo d-none d-md-block"></span>
    <span class="logo-mobile d-block d-md-none"></span>
  </a>

  <div class="navbar-right">
    <!-- <div class="header-icons d-inline-block align-middle">
      <div class="position-relative d-inline-block">
        <div dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            id="notificationButton"
            class="header-icon btn btn-empty"
            (click)="markRead()"
          >
            <i class="simple-icon-bell"></i>
            <span class="count" *ngIf="total_unread">{{ total_unread }}</span>
          </button>
          <div
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right mt-3"
            id="notificationDropdown"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()"
            [scrollWindow]="false"
          >
            <div *ngIf="notificationsData?.notifications.length">
              <div
                class="d-flex flex-row pb-3 border-bottom"
                *ngFor="let notification of notificationsData.notifications"
                [ngClass]="
                  notificationsData.notifications.length > 1 ? 'mb-3' : ''
                "
              >
                <a href="javascript:;">
                  <img
                    src="/assets/soleekImages/notification-type-1.png"
                    *ngIf="notification.type == '1'"
                    alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                  />
                  <img
                    src="/assets/soleekImages/notification-type-2.png"
                    alt="Notification Image"
                    *ngIf="notification.type == '2'"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                  />
                  <img
                    src="/assets/soleekImages/notification-type-3.png"
                    alt="Notification Image"
                    *ngIf="notification.type == '3'"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                  />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p
                      [routerLink]="[
                        '/app/reports/details',
                        notification.report_hash
                      ]"
                      class="font-weight-medium mb-1"
                    >
                      {{ notification.message }}
                    </p>
                    <p class="text-muted mb-0 text-small">
                      {{ notification.create_date }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="notificationsData?.notifications.length == '0'">
              <p class="no-notification">No notification</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{ displayName }}</span>
        <span>
          <img alt="Profile Picture" src="/assets/soleekImages/avatar.png" />
        </span>
      </button>

      <div
        class="dropdown-menu dropdown-menu-right mt-3"
        *dropdownMenu
        role="menu"
      >
        <a
          routerLink="/user/forgot-password"
          class="dropdown-item c-pointer"
          (click)="showChangePassword(ChangePasswordComponent)"
          >Change Password</a
        >
        <!-- <li class="divider dropdown-divider"></li> -->
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
      </div>
    </div>
  </div>
</nav>
<ng-template #ChangePasswordComponent>
  <app-change-password (closeModal)="modalRef.hide()"></app-change-password>
</ng-template>
