import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthUsersService } from 'src/app/views/user/services/auth-users.service';
import { StorageService } from '../storage.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Injectable({
  providedIn: 'root',
})

/**
 * Error Interceptor
 * An Interceptor for handle general errors
 */
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthUsersService,
    private router: Router,
    private storage: StorageService,
    private notifications: NotificationsService
  ) {}

  logout() {
    this.storage
      .clearStorage()
      .pipe(
        switchMap(() => this.auth.logout()),
        take(1)
      )
      .subscribe(() => this.router.navigate(['/user/login']));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.status !== 200) {
              console.log('1');
              console.log(
                '🚀 ~ file: error-handling.interceptor.ts ~ line 64 ~ ErrorHandlingInterceptor ~ event.body.message',
                event.body.message
              );
              this.notifications.create(
                'Error',
                event.body.message,
                NotificationType.Error,
                {
                  theClass: 'outline danger',
                  timeOut: 6000,
                  showProgressBar: false,
                }
              );
            }
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 500) this.router.navigate(['/error']);
            else if (error.status === 425) this.logout();
          }
        }
      )
    );
  }
}
