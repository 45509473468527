import { Injectable, SkipSelf } from '@angular/core';
import { HttpService } from 'src/app/shared/http-service.service';
import { HelperService } from 'src/app/shared/helper.service';
import { Observable } from 'rxjs';
import { APIURL } from 'src/app/basic-shared/api';

@Injectable({
  providedIn: 'root',
})
export class AuthUsersService {
  constructor(private http: HttpService, private helper: HelperService) {}

  /**
   * Login method
   *
   * @param {Login} credential - login form data
   * @returns {Observable<any>}
   * @memberof AuthUsersService
   */
  login(credential: Login): Observable<any> {
    return this.http.postReq(APIURL.users.login, credential);
  }

  changePassword(data): Observable<any> {
    return this.http.postReq(APIURL.users.changePassword, data);
  }

  /**
   * logout method
   *
   * @param {fromAll} logout
   * @returns {Observable<any>}
   * @memberof AuthUsersService
   */
  logout(fromAll: number = 1): Observable<any> {
    return this.http.postReq(APIURL.users.logout, null);
  }

  /**
   * forget password method
   *
   * @param {string} email
   * @returns {Observable<any>}
   * @memberof AuthUsersService
   */
  // forgetPassword(email: string): Observable<any> {
  //   return this.http.postReq(URLS.forgetPassword, email);
  // }

  /**
   * reset password method
   *
   * @param {ForgetPassword} credential
   * @returns {Observable<any>}
   * @memberof AuthUsersService
   */
  // resetPassword(credential: ForgetPassword): Observable<any> {
  //   return this.http.postReq(URLS.resetPassword, credential);
  // }
}

interface Login {
  email: string;
  password: string;
}

interface ForgetPassword {
  email: string;
  new_password: string;
  code: string;
}
