// import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewsComponent } from './views.component';
import { ErrorComponent } from './error/error.component';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  loggedIn,
} from '@angular/fire/auth-guard';
import { environment } from './../../environments/environment';
import { AuthGuard } from '../shared/guards/auth.guard';
import { LoginGuard } from '../shared/guards/login.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/user']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['/app']);

let routes: Routes = [
  {
    path: '',
    component: ViewsComponent,
    pathMatch: 'full',
  },
  {
    path: 'app',
    loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    canActivate: [AngularFireAuthGuard],

    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    data: { authGuardPipe: redirectLoggedInToItems },
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '/error' },
];

if (!environment.isAuthGuardActive)
  routes = [
    {
      path: '',
      component: ViewsComponent,
      pathMatch: 'full',
    },
    // {
    //   path: 'app/change-password',
    //   component: ChangePasswordComponent,
    // },
    {
      path: 'app',
      loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
      canLoad: [AuthGuard],
    },
    {
      path: 'user',
      loadChildren: () =>
        import('./user/user.module').then((m) => m.UserModule),
      canActivate: [LoginGuard],
    },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: '/error' },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, LoginGuard],
})
export class ViewRoutingModule { }
