export const APIURL = {
  website: {
    loginAsCustomer: 'https://shop.wastemarche.com/home?token=',
    offerView: 'https://shop.wastemarche.com/offer/',
    requestView: 'https://shop.wastemarche.com/request/',
    complainsView: 'https://shop.wastemarche.com/offer/',
    offerCustomer: 'https://shop.wastemarche.com/offer',
  },
  users: {
    login: '/dashboard/login',
    logout: '/dashboard/logout',
    changePassword: '/dashboard/change_password',
  },
  general: {
    upload: '/upload',
  },
  dashboard: {
    info: '/dashboard/get_dashbaord_info',
  },
  categories: {
    all: '/dashboard/sectors/all',
    one: '/dashboard/sectors',
    add: '/dashboard/sectors',
    update: '/dashboard/sectors/update',
    delete: '/dashboard/sectors/delete',
    archive: '/dashboard/sectors/archive',
    activate: '/dashboard/sectors/activate',
  },
  complains: {
    all: '/dashboard/complains/all',
    activate: '/dashboard/complains/activate',
  },
  trustPoints: {
    all: '/dashboard/trust_factors/all',
    one: '/dashboard/trust_factors',
    add: '/dashboard/trust_factors',
    update: '/dashboard/trust_factors/update',
    delete: '/dashboard/trust_factors/delete',
    archive: '/dashboard/trust_factors/archive',
    activate: '/dashboard/trust_factors/activate',
  },
  customers: {
    all: '/dashboard/customers/all',
    one: '/dashboard/customer',
    add: '/dashboard/customer',
    update: '/dashboard/customer/update',
    delete: '/admin/deactivate',
    verify: '/dashboard/customer/verify',
    activate: '/dashboard/customer/activate',
    export: '/dashboard/customers/export',
  },
  admin: {
    all: '/users',
    one: '/users',
    add: '/admin',
    update: '/admin',
    delete: '/admin/deactivate',
    archive: '/admin/deactivate',
    activate: '/admins/activate',
  },
  units: {
    all: '/dashboard/units/all',
    one: '/dashboard/units',
    add: '/dashboard/units',
    update: '/dashboard/units/update',
    delete: '/dashboard/units/delete',
    archive: '/dashboard/units/archive',
    activate: '/dashboard/units/activate',
  },
  autoComplete: {
    all: '/dashboard/autocomplete_list',
    one: '/dashboard/units',
    add: '/dashboard/autocomplete_list',
    update: '/dashboard/autocomplete_list',
    delete: '/dashboard/units/autocomplete_list',

  },
  cities: {
    all: '/dashboard/cities/all',
    one: '/dashboard/cities',
    add: '/dashboard/cities',
    update: '/dashboard/cities/update',
    delete: '/dashboard/cities/delete',
    archive: '/dashboard/cities/archive',
    activate: '/dashboard/cities/activate',
  },

  lookups: {
    areas: '/areas',
    companyTypes: '/company_types',
    cities: '/cities',
    companySize: '/company_sizes',
    equipments: '/equipment_availabilities',
    legalEntity: '/legal_entity_types',
    sectors: '/sectors',
    trustPoints: '/trustpoints',
    customers: '/customers',
  },
  offers: {
    all: '/offers/all?is_request=0',
    one: '/offers',
    customer_offer: '/dashboard/offers/customers',
    customer_trust: '/offer/trust_points',
    add: '',
    update: '',
    delete: '',
    archive: '',
    activate: '/offers/activate',
    export: '/offers_export',
  },
  requests: {
    all: '/offers/all?is_request=1',
    one: '',
    customer_request: '/dashboard/offers/customers',
    customer_trust: '/offer/trust_points',
    add: '',
    update: '',
    delete: '',
    archive: '',
    activate: '/offers/activate',
  },
  notifications: {
    all: '/dashboard/notifications/all',
    one: '',
    add: '/dashboard/send_message',
    update: '',
    delete: '',
    archive: '',
    activate: '',
  },
};
