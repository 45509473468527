import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from './helper.service';

@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  constructor(private helper: HelperService) {}
  transform(value: string) {
    if (!value) return value;
    return this.helper.relativeTime(value);
  }
}
