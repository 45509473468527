import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}

  public relativeTime(date: any): string {
    const previous = new Date(date).getTime();
    const current = Date.now();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < msPerMinute)
      return Math.round(elapsed / 1000) + ' seconds ago';
    else if (elapsed < msPerHour)
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    else if (elapsed < msPerDay)
      return Math.round(elapsed / msPerHour) + ' hours ago';
    else if (elapsed < msPerMonth)
      return Math.round(elapsed / msPerDay) + ' days ago';
    else if (elapsed < msPerYear)
      return Math.round(elapsed / msPerMonth) + ' months ago';
    else return Math.round(elapsed / msPerYear) + ' years ago';
  }

  private buildFormData(formData, data, parentKey?) {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    )
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  formData(data) {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  }
}
