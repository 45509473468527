import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  /**
   * Save items to local storage
   * By key, value pairs
   * @param {string} key the name of property
   * @param {string} value the value we need to store
   * @returns {Observable<void>}
   * @memberof StorageService
   */
  setItem(key: string, value: string): Observable<void> {
    return of(localStorage.setItem(key, value));
  }

  /**
   * Get the value from local storage for a given property
   * @param {string} key the key of the item we need
   * @returns {Observable<string>} the value of the given key
   * @memberof StorageService
   */
  getItem(key: string): Observable<string> {
    return of(localStorage.getItem(key));
  }

  /**
   * Get the value from local storage for a given property
   * @param {string} key the key of the item we need
   * @returns {Observable<string>} the value of the given key
   * @memberof StorageService
   */
  getStringItem(key: string): string {
    return localStorage.getItem(key);
  }

  /**
   * Check if the user type is admin or not
   *
   * @returns {boolean}
   * @memberof StorageService
   */
  isAdmin(): boolean {
    return +localStorage.getItem('role') === 0;
  }

  /**
   * Get the token for the current active user
   * @returns {string} User Token
   * @memberof StorageService
   */
  getToken(): string {
    return localStorage.getItem('token');
  }

  /**
   * Clear the localstorge and active variables
   * @returns {Observable<void>}
   * @memberof StorageService
   */
  clearStorage(): Observable<void> {
    location.reload();
    return of(localStorage.clear());
  }
}
